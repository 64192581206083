import React, { ReactNode } from 'react';
import {
	ScreenLayout,
	ScreenContentHeader,
	ScreenContentBody,
	ScreenContentBodyContainer,
	ScreenContentHeaderMain
} from '../shared/screen/index.ts';
import { LogoLink } from '../shared/LogoLink.tsx';
import UserBlock from './UserBlock.tsx';

export interface AppLayoutProps {
	children?: ReactNode;
}

export function AppLayout({ children }: AppLayoutProps) {
	return (
		<>
				<UserBlock />

			<>
				<ScreenContentBodyContainer>{children}</ScreenContentBodyContainer>
			</>
		</>
	);
}

export default AppLayout;
