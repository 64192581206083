import React from 'react';

export function EosLogo() {
  return (
    <svg
      viewBox="0 0 42 48"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-2610.000000, -1236.000000)"
          // fill="#443F54"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g transform="translate(2610.000000, 1236.000000)">
            <path
              d="M16.1,0 L4.8,15.5 L0,38.3 L16.1,48 L32.2,38.3 L27.4,15.4 L16.1,0 Z M7.4,15.9 L16.1,4 L24.8,15.9 L16.1,42 L7.4,15.9 Z M26,19.8 L29.6,37.2 L17.8,44.3 L26,19.8 Z M2.6,37.2 L6.2,19.8 L14.4,44.3 L2.6,37.2 Z"
              id="Shape"
            />
            <polygon
              id="Shape"
              points="35.4 44.6 34.2 44.6 34.2 44.3 36.9 44.3 36.9 44.6 35.7 44.6 35.7 48 35.4 48"
            />
            <polygon
              id="Shape"
              points="41 44.7 39.6 48 39.5 48 38.2 44.7 38.2 48 37.9 48 37.9 44.3 38.4 44.3 39.6 47.3 40.8 44.3 41.3 44.3 41.3 48 41 48"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
