import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LoginScreen from './login/LoginScreen.tsx';
// import AuthenticatedRoute from './core/AuthenticatedRoute';
import { AppLayout } from './core/AppLayout.tsx';
// import { HomeScreen } from './HomeScreen';
// import { TestScreen } from './TestScreen';

export function AppRoutes() {
	return (
		<AppLayout>
			<Switch>
				<LoginScreen>
				</LoginScreen>
			</Switch>
		</AppLayout>
	);
}

export default AppRoutes;
